import { ITableCellProps } from 'client/dsm/Table/Table'
import { IObjectWithLocales } from 'shared/json/IObjectWithLocales'
import GlobeIconTooltip from 'client/components/Languages/GlobeIconTooltip'
import {
  GQLExhibit,
  GQLItem,
  GQLImage,
  GQLAudio,
  GQLVideo,
  GQLLocale
} from 'shared/graphql/types/graphql'
import { getItemOrExhibitImageHero } from 'client/util/images'
import _ from 'lodash'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import { flash } from 'client/redux/actions/flash'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { useDispatch } from 'react-redux'
import { useMemo, useState } from 'react'
import { ExhibitionType } from 'shared/constants/exhibits'
import UpdateItemVisibilityDialog from 'client/screens/Catalog/grids/shared/UpdateItemVisibilityDialog'
import UpdateExhibitVisibilityDialog from 'client/screens/Catalog/grids/shared/UpdateExhibitVisibilityDialog'
import { t } from 'client/i18n'
import GenerateQRCodesDialog from 'client/screens/Catalog/grids/shared/GenerateQRCodesDialog'
import { format, getUnixTime, isToday } from 'date-fns'
import { UNIVERSAL_DATE_FORMAT } from 'shared/constants/dates'
import DownloadAssetIconButton from 'client/screens/Catalog/grids/shared/DownloadAssetIconButton'
import { AssetType } from 'shared/constants/asset'
import MultilineTooltip from 'client/screens/Catalog/grids/shared/MultilineTooltip'
import { getExhibitDate } from 'client/util/exhibits'
import { ImageContainer } from 'client/components/ContentRow/ImageContainer'
import HTMLText from 'client/components/HTMLText/HTMLText'
import styled from 'styled-components'
import CloseExhibitionDialog from './CloseExhibitionDialog'
import ExhibitionTourConversionConfirmation from './ExhibitionTourConversionConfirmation'

const OVERFLOW_MENU_COLUMN_SIZE_PROPS = {
  width: 36,
  flexGrow: 0
}

export const MEDIA_COLUMN_SPACING = {
  // The media column needs custom spacing to meet spec.
  // It is easier to do it here than update the
  // react virtualized table specs and the shared renderers.
  style: { marginRight: 8, overflow: 'visible' },
  headerStyle: { marginRight: 8 }
}

export const MEDIA_COLUMN_IMAGE_PROPS = { minWidth: 140, maxWidth: 140, ...MEDIA_COLUMN_SPACING }

// min width is added to make sure there is enough space to show sort icon without siblings having to shift
export const FLEXIBLE_COLUMN_SMALL_PROPS = { flexGrow: 1, minWidth: 100, maxWidth: 130 }

export const LANGUAGES_COLUMN = {
  dataKey: 'languages',
  label: t('Languages'),
  sortBy: (row: { locales?: GQLLocale[] }) => row.locales?.length,
  cellRenderer: ({ rowData }: ITableCellProps<IObjectWithLocales>) => (
    <GlobeIconTooltip locales={rowData?.locales} />
  ),
  ...FLEXIBLE_COLUMN_SMALL_PROPS
}

export const ITEM_EXHIBIT_IMAGE_COLUMN = {
  dataKey: 'hero',
  label: t('Preview'),
  sortable: false,
  cellRenderer: ({ rowData }: ITableCellProps<GQLItem | GQLExhibit>) => (
    <ImageContainer image={getItemOrExhibitImageHero(rowData)} isGridRow={true} />
  ),
  ...MEDIA_COLUMN_IMAGE_PROPS
}

export const LOOKUP_NUMBER_COLUMN = {
  dataKey: 'lookupNumber',
  label: t('Number'),
  ...FLEXIBLE_COLUMN_SMALL_PROPS
}

export const VISIBILITY_COLUMN = {
  dataKey: 'published',
  label: t('Visibility'),
  cellRenderer: ({ cellData }: ITableCellProps<any>) => (cellData ? t('Visible') : t('Hidden')),
  ...FLEXIBLE_COLUMN_SMALL_PROPS
}

interface IItemOverflowMenuRendererProps {
  rowData: GQLItem
}

function ItemOverflowMenuRenderer({ rowData }: IItemOverflowMenuRendererProps) {
  const { MARKETING } = useFeatureFlags()
  const { id, uuid, published } = rowData
  const [showUpdateVisibilityDialog, setShowUpdateVisibilityDialog] = useState(false)
  const [showGenerateQRCodesDialog, setShowGenerateQRCodesDialog] = useState(false)
  const dispatch = useDispatch()
  const updateVisibilityLabel = published ? t('Hide Item') : t('Make Item Visible')

  const menuOptions: IMenuOption[] = _.compact([
    {
      label: t('Get QR Codes'),
      onClick: () => {
        setShowGenerateQRCodesDialog(true)
      }
    },
    MARKETING && {
      label: t('Copy UUID'),
      onClick: async () => {
        await navigator.clipboard.writeText(uuid)
        dispatch(flash(t('Copied to clipboard')))
      }
    },
    {
      label: updateVisibilityLabel,
      onClick: () => setShowUpdateVisibilityDialog(true)
    }
  ])
  return (
    <>
      <MenuPopout options={menuOptions} appendTo={document.body} placement="bottom-end" />
      {showUpdateVisibilityDialog && (
        <UpdateItemVisibilityDialog
          id={rowData.id}
          onClose={() => setShowUpdateVisibilityDialog(false)}
        />
      )}
      {showGenerateQRCodesDialog && (
        <GenerateQRCodesDialog
          contentName={(rowData.title || rowData.scientificName || rowData.commonName)!}
          onDownload={() => {
            window.open(`/api/qrcodes/lookup/item/${id}`)
            setShowGenerateQRCodesDialog(false)
          }}
          onClose={() => {
            setShowGenerateQRCodesDialog(false)
          }}
        />
      )}
    </>
  )
}

export const ITEM_OVERFLOW_MENU_COLUMN = {
  dataKey: '',
  sortable: false,
  cellRenderer: ({ rowData }: ITableCellProps<GQLItem>) => (
    <ItemOverflowMenuRenderer rowData={rowData} />
  ),
  ...OVERFLOW_MENU_COLUMN_SIZE_PROPS
}

interface IExhibitionTourOverflowMenuRendererProps {
  rowData: GQLExhibit
}

function ExhibitionTourOverflowMenuRenderer({ rowData }: IExhibitionTourOverflowMenuRendererProps) {
  const dispatch = useDispatch()
  const { MARKETING } = useFeatureFlags()
  const [showExhibitionTourConversionDialog, setShowExhibitionTourConversionDialog] =
    useState(false)
  const [showCloseExhibitionDialog, setShowCloseExhibitionDialog] = useState(false)
  const [showGenerateQRCodesDialog, setShowGenerateQRCodesDialog] = useState(false)

  const isEvent = rowData.type === ExhibitionType.EVENT
  const isExhibition = rowData.type === ExhibitionType.EXHIBITION
  const [showUpdateVisibilityDialog, setShowUpdateVisibilityDialog] = useState(false)
  const updateVisibilityLabel = useMemo(() => {
    /* eslint-disable docent/require-translation-keys-to-be-literals */
    const type = _.capitalize(rowData.type) as Capitalize<`${ExhibitionType}`>
    return rowData.published ? t(`Hide ${type}`) : t(`Make ${type} Visible`)
    /* eslint-enable docent/require-translation-keys-to-be-literals */
  }, [rowData.published, rowData.type])

  const menuOptions: IMenuOption[] = _.compact([
    {
      label: t('Get QR Codes'),
      onClick: () => {
        setShowGenerateQRCodesDialog(true)
      }
    },
    MARKETING && {
      label: t('Copy UUID'),
      onClick: async () => {
        await navigator.clipboard.writeText(rowData.uuid)
        dispatch(flash(t('Copied to clipboard')))
      }
    },
    isExhibition && {
      label: t('Close Exhibition'),
      onClick: () => {
        setShowCloseExhibitionDialog(true)
      }
    },
    {
      label: updateVisibilityLabel,
      onClick: () => setShowUpdateVisibilityDialog(true)
    },
    !isEvent && {
      label: isExhibition ? t('Convert Exhibition to Tour') : t('Convert Tour to Exhibition'),
      onClick: () => {
        setShowExhibitionTourConversionDialog(true)
      }
    }
  ])

  return (
    <>
      <MenuPopout options={menuOptions} appendTo={document.body} placement="bottom-end" />
      {showExhibitionTourConversionDialog && (
        <ExhibitionTourConversionConfirmation
          exhibit={rowData}
          onClose={() => setShowExhibitionTourConversionDialog(false)}
        />
      )}
      {showCloseExhibitionDialog && (
        <CloseExhibitionDialog
          id={rowData.id}
          onClose={() => setShowCloseExhibitionDialog(false)}
        />
      )}
      {showUpdateVisibilityDialog && (
        <UpdateExhibitVisibilityDialog
          id={rowData.id}
          onClose={() => {
            setShowUpdateVisibilityDialog(false)
          }}
        />
      )}
      {showGenerateQRCodesDialog && (
        <GenerateQRCodesDialog
          contentName={rowData.title}
          onDownload={() => {
            window.open(`/api/qrcodes/lookup/exhibit/${rowData.id}`)
            setShowGenerateQRCodesDialog(false)
          }}
          onClose={() => {
            setShowGenerateQRCodesDialog(false)
          }}
        />
      )}
    </>
  )
}

export const EXHIBIT_OVERFLOW_MENU_COLUMN = {
  // `dataKey` is a required prop, but unused here because we're pulling the data from the row rather than the cell
  dataKey: '',
  sortable: false,
  cellRenderer: ({ rowData }: ITableCellProps<GQLExhibit>) => (
    <ExhibitionTourOverflowMenuRenderer rowData={rowData} />
  ),
  ...OVERFLOW_MENU_COLUMN_SIZE_PROPS
}

export const FILE_NAME_COLUMN = {
  dataKey: 'fileName',
  label: t('File Name')
}

export const UPDATED_AT_COLUMN = {
  dataKey: 'updatedAt',
  label: t('Updated'),
  cellRenderer: ({ cellData }: ITableCellProps<string>) => {
    return isToday(cellData)
      ? format(cellData, 'hh:mm:ss a')
      : format(cellData, UNIVERSAL_DATE_FORMAT)
  },
  sortBy: (row: { updatedAt: string }) => getUnixTime(row.updatedAt),
  ...FLEXIBLE_COLUMN_SMALL_PROPS
}

export const EXHIBIT_DATE_AND_TIME_COLUMN = {
  dataKey: 'from',
  label: t('Date & Time'),
  cellRenderer: ({ rowData }: ITableCellProps<GQLExhibit>) => getExhibitDate(rowData),
  sortBy: (row: { from: string }) => getUnixTime(row.from)
}

const getNameForUsedInColumn = (rowData: GQLImage | GQLAudio | GQLVideo) => {
  const { items, exhibits } = rowData
  return [
    ..._.map(items, (item) => (item.title || item.commonName || item.scientificName) as string),
    ..._.map(exhibits, 'title')
  ]
}

const getDisplayNameForUsedInColumn = (rowData: GQLImage | GQLAudio | GQLVideo) => {
  return getNameForUsedInColumn(rowData).join(', ')
}

const cellRendererForUsedInColumn = ({
  rowData
}: ITableCellProps<GQLImage | GQLAudio | GQLVideo>) => {
  const names = getDisplayNameForUsedInColumn(rowData)
  return (
    <MultilineTooltip lines={getNameForUsedInColumn(rowData)}>
      <HTMLText html={names} />
    </MultilineTooltip>
  )
}

function showDownloadIconButtonTooltip({
  rowData,
  cellData: isMarketingUseAllowed
}: ITableCellProps<GQLImage | GQLAudio | GQLVideo>) {
  const assetType = _.toLower(_.get(rowData!, '__typename'))
  const sourceUrl = _.get(rowData!, 'sourceUrl')
  const hasSourceUrl = !_.isNil(sourceUrl)
  return (
    <DownloadAssetIconButton
      assetType={assetType as AssetType}
      assetId={rowData.id}
      hasSourceUrl={hasSourceUrl}
      isMarketingUseAllowed={isMarketingUseAllowed}
    />
  )
}

export const DOWNLOAD_COLUMN = {
  dataKey: 'isMarketingUseAllowed',
  label: t('Download'),
  cellRenderer: showDownloadIconButtonTooltip,
  ...FLEXIBLE_COLUMN_SMALL_PROPS
}

export const MEDIA_INCLUDED_IN_COLUMN = {
  dataKey: 'includedIn',
  label: t('Included In'),
  sortBy: getDisplayNameForUsedInColumn,
  cellRenderer: cellRendererForUsedInColumn,
  ...FLEXIBLE_COLUMN_SMALL_PROPS
}

export const MEDIA_INCLUDED_IN_FILTER_CRITERIA = [
  'items.title',
  'items.commonName',
  'items.scientificName',
  'items.creators.name',
  'exhibits.title'
]

function getExhibitsNames(rowData: GQLItem) {
  return _.map(rowData.exhibits, 'title')
}
function getExhibitsDisplayName(rowData: GQLItem) {
  return getExhibitsNames(rowData).join(', ')
}

const InlinePWrapper = styled(HTMLText)`
  p {
    display: inline;
  }
`
function itemIncludedInRenderer({ rowData }: ITableCellProps<GQLItem>) {
  const exhibits = getExhibitsDisplayName(rowData)
  return (
    <MultilineTooltip lines={getExhibitsNames(rowData)}>
      <InlinePWrapper html={exhibits} />
    </MultilineTooltip>
  )
}

export const ITEM_INCLUDED_IN_COLUMN = {
  dataKey: 'exhibits',
  label: t('Included In'),
  cellRenderer: itemIncludedInRenderer,
  sortBy: getExhibitsDisplayName
}

export const htmlRenderer = ({ cellData }: ITableCellProps<any>) => {
  return <HTMLText html={cellData} />
}
