import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import PageContent from 'client/components/PageContent/PageContent'
import Button from 'client/components/Button/Button'
import { PageContentContainer } from 'client/screens/Developer/styledComponents'
import useDeveloperPost from './useDeveloperPost'

export default function Developer() {
  const [clearCloudfrontCache, isClearCloudfrontCachePending] = useDeveloperPost(
    '/api/developer/clear-cloudfront-cache',
    'Clear Cloudfront Cache'
  )

  const [updateGooglePlacesInfo, isUpdatingGooglePlacesInfo] = useDeveloperPost(
    '/api/developer/update-google-places-info',
    'Update Google Places Info'
  )

  const [fetchAppsflyerLinkParams, isFetchingAppsflyerLinkParams] = useDeveloperPost(
    '/api/developer/fetch-appsflyer-link-params',
    'Fetch Appsflyer Link Params'
  )

  const [enableMobileWebForAllLiveGuides, isEnablingMobileWebForAllLiveGuides] = useDeveloperPost(
    '/api/developer/enable-mobile-web-for-live-guides',
    'Enable Mobile Web for Live Guides'
  )

  const [disableMobileWebForNonFirstCohortGuides, isDisablingMobileWebForNonFirstCohortGuides] =
    useDeveloperPost(
      '/api/developer/disable-mobile-web-for-non-first-cohort-guides',
      'Disable Mobile Web for Non First Cohort Guides'
    )

  const isLoading =
    isClearCloudfrontCachePending ||
    isUpdatingGooglePlacesInfo ||
    isFetchingAppsflyerLinkParams ||
    isEnablingMobileWebForAllLiveGuides ||
    isDisablingMobileWebForNonFirstCohortGuides

  return (
    <PageContent title="Developer Actions">
      {isLoading && <LoadingOverlay />}
      <PageContentContainer>
        <Button onClick={() => clearCloudfrontCache()} label="Clear Cloudfront Cache" />

        <Button
          onClick={() => clearCloudfrontCache({ path: '/graphql/*' })}
          label="Clear Cloudfront Cache for GraphQL"
        />

        <Button
          onClick={() => clearCloudfrontCache({ path: '/graphql/explore?*' })}
          label="Clear Cloudfront Cache for Explore"
        />

        <Button
          onClick={() => {
            updateGooglePlacesInfo()
          }}
          label="Update Google Places Info"
        />

        <Button
          onClick={() => {
            fetchAppsflyerLinkParams()
          }}
          label="Fetch Appsflyer Link Params"
        />

        <Button
          label="Enable Mobile Web for Live Guides"
          onClick={() => {
            enableMobileWebForAllLiveGuides()
          }}
        />

        <Button
          label="Disable Mobile Web for Non First Cohort Guides"
          onClick={() => {
            disableMobileWebForNonFirstCohortGuides()
          }}
        />
      </PageContentContainer>
    </PageContent>
  )
}
